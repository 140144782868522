import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, TextField, Button, CircularProgress, 
    Dialog, DialogTitle, DialogContent, DialogActions, Pagination, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../shared/axiosConfig';
// import BusinessForm from './BusinessForm';
import { Business, PaginatedResponse } from '../../shared/models';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import PercentageValue from '../../shared/components/PercentageValue';
import DateTimeValue from '../../shared/components/DateTimeValue';

const InvestmentHistory: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const [investments, setInvestments] = useState<any[]>([]);
    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState<Business | null>(null);
    const [currentOwnershipPercentage, setCurrentOwnershipPercentage] = useState<number | string>('');
    const [purchaseAmount, setPurchaseAmount] = useState<number | string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(20); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {
        fetchMyInvestmentHistory();
    }, [searchTerm, page]);

    const fetchMyInvestmentHistory = async () => {
        try {
            let url = 'investor-investments/';
            setLoading(true);
            const response = await axios.get<PaginatedResponse<any>>(url, {
                params: {
                    search: searchTerm,
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setInvestments(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch restaurants", error);
            showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
        setLoading(false)
    };

    const handleEdit = (investment: any) => {
        setSelectedRestaurant(investment.business);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentOwnershipPercentage('');
        setSelectedRestaurant(null);
        fetchMyInvestmentHistory();
    };
    const handlePercentageChange = (e: any) => {
        const currentPercentage = +e.target.value;
        const  totalValuation = selectedRestaurant?.stock_profile.total_valuation ? +selectedRestaurant?.stock_profile.total_valuation : 0
        const pamount =  totalValuation * (currentPercentage/100)
        setCurrentOwnershipPercentage(currentPercentage);
        setPurchaseAmount(pamount)
    }
    
    return (
        <div>
            <h2>My Transactions</h2>
            {loading ? (
                <CircularProgress />
            ) : (<>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ marginBottom: 20 }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Business</TableCell>
                            <TableCell>Percent</TableCell>
                            <TableCell>Transaction Amount</TableCell>
                            <TableCell>Transaction Type</TableCell>
                            <TableCell>Transaction Date</TableCell>
                            {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {investments.map((investment, index) => (
                            <TableRow key={investment.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{investment.business.name}</TableCell>
                                <TableCell>{<PercentageValue value={investment.ownership_percentage} />}</TableCell>
                                <TableCell>{<CurrencyAmount amount={investment.purchase_amount} />}</TableCell>
                                <TableCell>{investment.purchase_type}</TableCell>
                                <TableCell><DateTimeValue value={investment.purchase_date} /> </TableCell>
                                
                                {/* <TableCell>
                                    <Button onClick={() => handleEdit(investment)}>Edit</Button>
                                </TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                />
            </>
            )}
        </div>
        
    );
};

export default InvestmentHistory;