import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { SnackbarSeverity, useSnackbar } from '../services/SnackbarService';
import { User } from '../models';

interface AuthContextType {
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    isAuthenticated: boolean;
    userDetails: User
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<any>(false);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const accessToken = localStorage.getItem('access');
        const refreshToken = localStorage.getItem('refresh');
        let uDetails = localStorage.getItem('user_details');
        console.log("uDetails", uDetails);
        if (accessToken) {
            setIsAuthenticated(true);
        }
        if (uDetails) {
            uDetails = JSON.parse(uDetails);
            setUserDetails(uDetails);
        }
    }, [])

    const login = async (username: string, password: string) => {
        try {
            const response = await axios.post('token/', { username, password });
            localStorage.setItem('access', response.data.access);
            localStorage.setItem('refresh', response.data.refresh);
            const user_details_response = await axios.get('get_logged_in_user/');
            localStorage.setItem('user_details', JSON.stringify(user_details_response.data));
            setIsAuthenticated(true);
            setUserDetails(user_details_response.data);
            showSnackbar("Login Success", SnackbarSeverity.SUCCESS);
            window.location.href = "/"
        } catch (error) {
            showSnackbar("Login Failed", SnackbarSeverity.ERROR)
        }
    };

    const logout = () => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('user_details');
        setIsAuthenticated(false);
        window.location.href = "/login"
    };

    return (
        <AuthContext.Provider value={{ login, logout, isAuthenticated, userDetails }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};