import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from '../shared/axiosConfig';
import { Investor } from '../shared/models';

interface InvestorFormProps {
    investor: Investor | null;
    onClose: () => void;
}

const InvestorForm: React.FC<InvestorFormProps> = ({ investor, onClose }) => {
    const { register, handleSubmit, setValue } = useForm<Investor>();

    useEffect(() => {
        if (investor) {
            setValue('personal_profile', investor.personal_profile);
        } else {
            setValue('personal_profile', '');
        }
    }, [investor, setValue]);

    const onSubmit = async (data: Investor) => {
        if (investor) {
            await axios.put(`investors/${investor.id}/`, data);
        } else {
            await axios.post('investors/', data);
        }
        onClose();
    };

    return (
        <Dialog open={!!investor} onClose={onClose}>
            <DialogTitle>{investor ? 'Edit Investor' : 'Add Investor'}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField label="Personal Profile" {...register("personal_profile", { required: "Profile is required" })} fullWidth margin="normal" />
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">{investor ? 'Update' : 'Create'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default InvestorForm;