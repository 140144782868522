import React from 'react';

interface PercentageValueProps {
    value: number | string | undefined;
    decimalPlaces?: number;
}


// Utility function to format currency
const formatPercentage = (actualValue: number | string | undefined, decimalPlaces = 2) => {
    let value = actualValue
    if (actualValue !== '') {
        if ((typeof actualValue === 'number')) {
            value = `${actualValue}`
        }
        if ((typeof value === 'string')) {
            let amountValue = typeof(value) == 'string' ? +value : value
            return `${amountValue.toFixed(decimalPlaces)}%`;
        }
    }
    
    return '';
};

// Pure component for rendering currency values
const PercentageValue = (props: PercentageValueProps) => {
    const { value, decimalPlaces = 2 } = props;
    return (
        <span>{formatPercentage(value, decimalPlaces)}</span>
    );
};

export default React.memo(PercentageValue);