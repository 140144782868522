import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import axios from '../../shared/axiosConfig';
import { Business, PaginatedResponse, PurchaseType, Revenue } from '../../shared/models';
import { useAuth } from '../../shared/context/AuthContext';
import dayjs, { Dayjs } from 'dayjs';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import PercentageValue from '../../shared/components/PercentageValue';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";




interface InvestmentFormProps {
    selectedRestaurant: Business | null;
    purchaseType: PurchaseType.BUY | PurchaseType.SELL;
    onClose: () => void;
    isOpen: boolean;
}
interface FormInputs {
    ownership_percentage: string;
    
}
const InvestmentForm: React.FC<InvestmentFormProps> = ({ selectedRestaurant, onClose, isOpen, purchaseType }) => {
    const { userDetails } = useAuth();
    const [loading, setLoading] = useState<boolean>(true);
    const [currentInvestment, setCurrentInvestment] = useState<any>();
    const [currentSellAvailability, setCurrentSellAvailability] = useState<number>(0);
    
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const [currentOwnershipPercentage, setCurrentOwnershipPercentage] = useState<number | string>('');
    const [purchaseAmount, setPurchaseAmount] = useState<number | string>('');
    const [sellingPrince, setSellingPrice] = useState<number | string>('');

    const schema = Yup.object().shape({
    
        ownership_percentage: Yup.number().label("Ownership Percentage").when("user_type", ([user_type], schema) => {
            if (user_type === "restaurant_owner") {
                return schema.required("Ownership Percentage is required").min(1, "Ownership Percentage needs to be more than 1%").max(99, "Ownership Percentage needs to be less than 99%");
            }
            return schema.notRequired();
        })
    });

    const { control, register, handleSubmit, setValue, formState: {errors}, trigger, watch } = useForm<FormInputs, any, FormInputs>({
        mode: "onChange",
    });

    const fetchMyCurrentInvestment = async () => {
        try {
            let url = 'investor-businesses/current_investment_business/';
            setLoading(true);
            const response = await axios.get<any>(url, {
                params: {
                    business_id: selectedRestaurant?.id
                }
            });
            setCurrentInvestment(response.data);
        } catch (error) {
            console.error("Failed to fetch current Investment", error);
            showSnackbar("Failed to fetch current Investment. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
        setLoading(false)
    };
    const fetchAvailableToSell = async () => {
        try {
            let url = 'investor-businesses/get_available_to_sell/';
            setLoading(true);
            const response = await axios.get<any>(url, {
                params: {
                    business_id: selectedRestaurant?.id
                }
            });
            setCurrentSellAvailability(+response.data.total_available_to_sell);
        } catch (error) {
            console.error("Failed to fetch current available to sell", error);
            showSnackbar("Failed to fetch current available to sell. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
        setLoading(false)
    };

    useEffect(() => {
        if (selectedRestaurant) {
            fetchMyCurrentInvestment();
            fetchAvailableToSell();
            resetValues();
        }
    }, [selectedRestaurant, isOpen])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log(value, name, type)
            if (name === 'ownership_percentage') {
                const currentPercentage = value.ownership_percentage ? +value.ownership_percentage : 0;
                const  totalValuation = selectedRestaurant?.stock_profile.total_valuation ? +selectedRestaurant?.stock_profile.total_valuation : 0
                const pamount =  totalValuation * (currentPercentage/100)
                setPurchaseAmount(pamount)
                setSellingPrice(pamount)
            }
            // const currentPercentage = +e.target.value;
            // const  totalValuation = selectedRestaurant?.stock_profile.total_valuation ? +selectedRestaurant?.stock_profile.total_valuation : 0
            // const pamount =  totalValuation * (currentPercentage/100)
            // setPurchaseAmount(pamount)
            // setSellingPrice(pamount)
        })
        return () => subscription.unsubscribe()
      }, [watch, selectedRestaurant])

    const resetValues = () => {
        setValue('ownership_percentage', '');
        setPurchaseAmount(0);
        setSellingPrice(0);
    }
    const handlePercentageChange = (e: any) => {
        const currentPercentage = +e.target.value;
        // const  totalValuation = selectedRestaurant?.stock_profile.total_valuation ? +selectedRestaurant?.stock_profile.total_valuation : 0
        // const pamount =  totalValuation * (currentPercentage/100)
        setCurrentOwnershipPercentage(currentPercentage);
        // setPurchaseAmount(pamount)
        // setSellingPrice(pamount)
        // trigger("ownership_percentage")
    }

    const onSubmit = async (data: any) => {
        // Logic for handling investment submission
        if (selectedRestaurant) {
            try {
                let url = 'investor-investments/';
                const response = await axios.post(url, {
                    business: selectedRestaurant.id,
                    ownership_percentage: data.ownership_percentage,
                    purchase_amount: purchaseAmount,
                    purchase_type: purchaseType
                });
                if (purchaseType == PurchaseType.BUY) {
                    showSnackbar(`Investment of ${data.ownership_percentage}% initiated for ${selectedRestaurant.name}!`, SnackbarSeverity.SUCCESS); 
                } else {
                    showSnackbar(`Sale of ${data.ownership_percentage}% initiated for ${selectedRestaurant.name}!`, SnackbarSeverity.SUCCESS); 
                }
            } catch (error) {
                console.error("Failed to save investment", error);
                showSnackbar("Failed to save investment. Please try again.", SnackbarSeverity.ERROR);
            } finally {
                // setLoading(false);
            }
            onClose()
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            {
                purchaseType == PurchaseType.BUY && <DialogTitle>Invest in {selectedRestaurant?.name}</DialogTitle>
            }
            {
                purchaseType == PurchaseType.SELL && <DialogTitle>Sell from {selectedRestaurant?.name}</DialogTitle>
            }
                
                <form noValidate onSubmit={handleSubmit(onSubmit)} >
                <DialogContent>
                    <Typography variant="h6" component="div">
                        Current: <PercentageValue value={currentInvestment && currentInvestment.current_ownership_percentage ? currentInvestment.current_ownership_percentage : '0'	} />
                    </Typography>
                    {
                        purchaseType == PurchaseType.BUY &&
                        <>
                            <Typography variant="h6" component="div">
                                Available: <PercentageValue value={selectedRestaurant?.stock_profile.available_ownership_to_sell} />
                            </Typography>
                            <Typography variant="h6" component="div">
                                Minimum Purchase: <PercentageValue value={selectedRestaurant?.stock_profile.minimum_purchase_value} />
                            </Typography>
                            
                        </>
                    }
                    {
                        purchaseType == PurchaseType.SELL &&
                        <>
                            <Typography variant="h6" component="div">
                                Available: <PercentageValue value={currentSellAvailability} />
                            </Typography>
                            
                        </>
                    }
                    <TextField
                            autoFocus
                            margin="dense"
                            label="Share Percentage"
                            type="number"
                            fullWidth
                            // value={currentOwnershipPercentage}
                            
                            inputProps={{ min: 0, max: 100, step: 0.01 }}
                            {...register("ownership_percentage", {
                                validate: (value) => {
                                    if (purchaseType == PurchaseType.BUY) {
                                        if (selectedRestaurant && selectedRestaurant.stock_profile) {
                                            const min_slab =  +(selectedRestaurant.stock_profile.minimum_purchase_value)
                                            if (+value < min_slab) {
                                                return `Value needs to be greater than ${min_slab}`
                                            }
                                            const available =  +(selectedRestaurant.stock_profile.available_ownership_to_sell)
                                            if (+value > available) {
                                                return `Value needs to be less than ${available}`
                                            }
                                        }
                                    }
                                    else if (purchaseType == PurchaseType.SELL) {
                                        // let availableToSel = 0;
                                        // if (currentInvestment && currentInvestment.current_ownership_percentage) {
                                        //     availableToSel =  +(currentInvestment.current_ownership_percentage)
                                        // }
                                        if (+value > currentSellAvailability) {
                                            return `Value needs to be less than or equal to ${currentSellAvailability}`
                                        } 
                                    }
                                    
                                }
                                    
                              })}
                            error={!!errors.ownership_percentage}
                            helperText={errors.ownership_percentage?.message}
                            // onChange={(e) => handlePercentageChange(e)}
                    />
                    {
                        purchaseType == PurchaseType.BUY && 
                        <Typography variant="h6" component="div">
                                Your price: <CurrencyAmount amount={purchaseAmount} />
                        </Typography>
                    }
                    {
                        purchaseType == PurchaseType.SELL && 
                        <Typography variant="h6" component="div">
                                Sale Value: <CurrencyAmount amount={purchaseAmount} />
                        </Typography>
                    }
                        
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Submit
                    </Button>
                </DialogActions>
                </form>
        </Dialog>
    );
};

export default InvestmentForm;