import React from 'react';
import dayjs, { Dayjs } from 'dayjs';

interface DateTimeValueProps {
    value: number | string;
    includeTime?: boolean;
}


// Utility function to format currency
const formatDateTimeValue = (value: number | string, includeTime = false) => {
    // let format = 'DD/MM/YYYY'
    let format = 'DD/MMM/YYYY'
    if (includeTime) {
        // format = 'DD/MM/YYYY H:mm:ss'
        format = 'DD/MMM/YYYY H:mm:ss'
    }
    return dayjs(value).format(format);
};

// Pure component for rendering currency values
const DateTimeValue = (props: DateTimeValueProps) => {
    const { value, includeTime = false } = props;
    return (
        <span>{formatDateTimeValue(value, includeTime)}</span>
    );
};

export default React.memo(DateTimeValue);