import React from 'react';

interface CurrencyAmountProps {
    amount: number | string;
    currency?: string;
    locale?: string;
}


// Utility function to format currency
const formatCurrency = (amount: number | string, currency = 'USD', locale = 'en-US') => {
    let amountValue = typeof(amount) == 'string' ? +amount : amount
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(amountValue);
};

// Pure component for rendering currency amounts
const CurrencyAmount = (props: CurrencyAmountProps) => {
    const { amount, currency = 'USD', locale = 'en-US' } = props;
    return (
        <span>{formatCurrency(amount, currency, locale)}</span>
    );
};

export default React.memo(CurrencyAmount);