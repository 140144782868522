import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Card, CardContent, CardActions, 
    Typography, Button, CircularProgress, 
    Dialog, DialogTitle, DialogContent, DialogActions, TextField, Pagination } from '@mui/material';
import axios from '../../shared/axiosConfig';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import { Business, PurchaseType } from '../../shared/models';
import debounce from 'lodash.debounce';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import { Controller, useForm } from 'react-hook-form';
import PercentageValue from '../../shared/components/PercentageValue';
import InvestmentForm from './InvestmentForm';

interface FormInputs {
    ownership_percentage: string;
    
}

const RestaurantList: React.FC = () => {
    const { control, register, handleSubmit, watch, formState: { errors }, setError } = useForm<FormInputs>();
    const [restaurants, setRestaurants] = useState<Business[]>([]);
    
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState<boolean>(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState<Business | null>(null);
    const [currentOwnershipPercentage, setCurrentOwnershipPercentage] = useState<number | string>('');
    const [purchaseAmount, setPurchaseAmount] = useState<number | string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    const fetchRestaurants = async () => {

        try {
            let url = 'businesses/';
            setLoading(true);
            const response = await axios.get(url, {
                params: {
                    search: searchTerm,
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setRestaurants(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch restaurants", error);
            showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchRestaurants();
    }, [searchTerm, page]);
    // }, []);

    const handleInvest = (restaurant: Business) => {
        setSelectedRestaurant(restaurant);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setCurrentOwnershipPercentage('');
        setSelectedRestaurant(null);
    };
    
    
    // const handleSubmit = async () => {
    //     // Logic for handling investment submission
    //     if (selectedRestaurant) {
    //         try {
    //             let url = 'investor-businesses/';
    //             setLoading(true);
    //             const response = await axios.post(url, {
    //                 business_id: selectedRestaurant.id,
    //                 current_ownership_percentage: currentOwnershipPercentage
    //             });
    //             showSnackbar(`Investment of ${currentOwnershipPercentage}% initiated for ${selectedRestaurant.name}!`, SnackbarSeverity.SUCCESS); 
    //         } catch (error) {
    //             console.error("Failed to save investment", error);
    //             showSnackbar("Failed to save investment. Please try again.", SnackbarSeverity.ERROR);
    //         } finally {
    //             setLoading(false);
    //         }
            
            
    //         handleClose();
    //     }
    // };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(1); // Reset to the first page on search
    };

    useEffect(() => {  
        return () => {
            debouncedResults.cancel();  
        };
    });

    const debouncedResults = useMemo(() => {  return debounce(handleSearch, 600);}, []);

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Restaurant List
            </Typography>
            <TextField
                label="Search Restaurants"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={handleSearch}
            />
            {loading ? (
                <CircularProgress />
            ) : (
                <>
                <Grid container spacing={3}>
                    {restaurants.map((restaurant) => (
                        <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5">
                                        {restaurant.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Total Valuation: ${restaurant.stock_profile?.total_valuation}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Available: {restaurant.stock_profile?.available_ownership_to_sell}%
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Minimum Investment: {restaurant.stock_profile?.minimum_purchase_value}%
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button 
                                        size="small" 
                                        color="primary" 
                                        onClick={() => handleInvest(restaurant)}
                                    >
                                        Invest
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                />
            </>
            )}
             {/* Investment Modal */}
             <InvestmentForm isOpen={open} selectedRestaurant={selectedRestaurant} onClose={handleClose} purchaseType={PurchaseType.BUY} />
        </div>
    );
};

export default RestaurantList;