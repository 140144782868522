import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../shared/axiosConfig';
import MonthlyRevenueForm from './MonthlyRevenueForm';
import { PaginatedResponse, Revenue } from '../../shared/models';
import { useAuth } from '../../shared/context/AuthContext';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import DateTimeValue from '../../shared/components/DateTimeValue';

const MonthlyRevenueList: React.FC = () => {
    const { userDetails } = useAuth();
    const [revenues, setRevenues] = useState<Revenue[]>([]);
    const [search, setSearch] = useState<string>('');
    const [editing, setEditing] = useState<Revenue | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchRevenues();
    }, []);

    const fetchRevenues = async () => {
        const business_id = userDetails?.business?.id
        const params: any = {}
        params['business'] = business_id
        const response = await axios.get<PaginatedResponse<Revenue>>('business-revenue/', {
            params: params
        });
        setRevenues(response.data.results);
    };

    const handleEdit = (revenue: Revenue) => {
        setEditing(revenue);
        setOpen(true);
    };
    const handleAdd = () => {
        setEditing(null);
        setOpen(true);
    };

    const onClose = () => { 
        setEditing(null); 
        setOpen(false);
        fetchRevenues(); 
    }


    const handleDelete = async (id: number) => {
        await axios.delete(`business-revenue/${id}/`);
        fetchRevenues();
    };

    return (
        <div>
            <h2>Restaurant Revenues</h2>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <TextField
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 20 }}
            />
            <Button variant="outlined" startIcon={<AddIcon />}
                onClick={() => handleAdd()}
                >
                Add
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                            <TableCell>Gross Revenue</TableCell>
                            <TableCell>Net Revenue</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {revenues.map(revenue => (
                            <TableRow key={revenue.id}>
                                <TableCell>{<DateTimeValue value={revenue.start_date} />}</TableCell>
                                <TableCell>{<DateTimeValue value={revenue.end_date} />}</TableCell>
                                <TableCell>{<CurrencyAmount amount={revenue.gross_revenue} />}</TableCell>
                                <TableCell>{<CurrencyAmount amount={revenue.net_revenue} />}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(revenue)}>Edit</Button>
                                    {/* <Button onClick={() => handleDelete(revenue.id)}>Delete</Button> */}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <MonthlyRevenueForm isOpen={open} revenue={editing} onClose={onClose} />
        </div>
    );
};

export default MonthlyRevenueList;