export enum UserType {
    INVESTOR="investor",
    RESTAURANT_OWNER="restaurant_owner"
}
export enum PurchaseType {
    BUY="buy",
    SELL="sell"
}
export interface PaginatedResponse<T> {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
    final_sold?: any | null;
}

export interface User {
    id: number,
    last_login: Date,
    is_superuser: boolean,
    username: string,
    first_name: string,
    last_name: string,
    email: string,
    is_staff: boolean,
    is_active: boolean,
    date_joined: Date,
    user_type: UserType,
    business: Business,
    groups:[],
    user_permissions:[]
}
export interface Business {
    id: number;
    name: string;
    company: string;
    is_restaurant: boolean;
    is_deleted: boolean;
    stock_profile: BusinessStockProfile;
}

export interface BusinessStockProfile {
    id: number;
    total_valuation: number|string;
    max_ownership_split: number|string;
    available_ownership_to_sell: number|string;
    minimum_purchase_slab: number|string;
    minimum_purchase_value: number|string;
    business: number|string;
    is_deleted: boolean;
}

export interface Investor {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    personal_profile: string;
    investor_business?: any;
}

export interface Revenue {
    id: number;
    start_date: string;
    end_date: string;
    gross_revenue: number|string;
    net_revenue: number|string;
    business: number|string; // or Restaurant type if you want more detail
}

export interface InvestorEarnings {
    id: number;
    business: Business;
    business_revenue: Revenue;
    earning_amount: number|string;
    earning_date: string;
    justification: string;
    staring_ownership: string|number;
    ending_ownership: string|number;
    avg_ownership: string|number;
    investor: Investor | string; // or Investor type if you want more detail
}

export interface InvestorProratedEarnings {

    prorated_earnings: number|string;
    prorated_ownership: number|string;
    current_ownership: number|string;
    start_date: string;
    end_date: string;
    period_days: number|string;
    cumulative_prorated_ownership: number|string;
}

export interface InvestorPayouts {
    id: number;
    payout_amount: number;
    pending_amount?: number;
    payout_date: string;
    investor: Investor; // or Investor type if you want more detail
    business: Business; // or Business type if you want more detail
}