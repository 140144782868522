import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Pagination, IconButton, Collapse, Box, Typography, Select, MenuItem } from '@mui/material';
import axios from '../../shared/axiosConfig';
import InvestorEarningsForm from './InvestorEarningsForm';
import { InvestorEarnings, InvestorProratedEarnings, PaginatedResponse } from '../../shared/models';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import DateTimeValue from '../../shared/components/DateTimeValue';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PercentageValue from '../../shared/components/PercentageValue';
import InvestorEarningsListDetail from './InvestorEarningsListDetail';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import InvestorEarningsListMonthDetail from './InvestorEarningsListMonthDetail';

const InvestorEarningsMonthList: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const [earnings, setEarnings] = useState<InvestorEarnings[]>([]);
    const lastMonth = dayjs().subtract(1, 'month')
    const [selectedMonth, setSelectedMonth] = useState<any>(lastMonth);
    const [showReported, setShowReported] = useState<string>("all");
    const [earningsOnDisplay, setEarningsOnDisplay] = useState<InvestorEarnings[]>([]);
    
    const [totalEarnings, setTotalEarnings] = useState<any>();
    const [search, setSearch] = useState<string>('');
    const [editing, setEditing] = useState<InvestorEarnings | null>(null);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {
        fetchEarnings();
    }, [selectedMonth]);
    
    useEffect(() => {
        const filtered = earnings.filter((e: any) => {
            if (showReported === 'all') {
                return true;
            }
            else if (showReported === 'reported') {
                return e.status === 'reported';
            }
            else if (showReported === 'not_reported') {
                return e.status === 'not_reported';
            }
        })
        setEarningsOnDisplay(filtered)
    }, [showReported]);
    
    const fetchEarnings = async () => {
        try {
            let url = 'investor-earnings/earnings_monthly/';
            setLoading(true);
            const response = await axios.get<{"earnings": InvestorEarnings[], "total": number}>(url, {
                params: {
                    month: selectedMonth.month() + 1,
                    year: selectedMonth.year(),
                }
            });
            setEarnings(response.data.earnings);
            setEarningsOnDisplay(response.data.earnings);
            setShowReported("all");
            setTotalEarnings(response.data.total);
        } catch (error) {
            console.error("Failed to fetch awaited earnings", error);
            showSnackbar("Failed to fetch awaited earnings. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
    };
    
    

    

    return (
        <div>
            <h2>My Earnings</h2>
            {/* <TextField
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 20 }}
            /> */}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h4>Total Earnings - <CurrencyAmount amount={totalEarnings} /></h4>
                <div>
                    <Select
                        style={{width: '20ch'}}
                        labelId="repoted-type-label"
                        value={showReported}
                        onChange={(e): void => {
                            setShowReported(e.target.value)
                        }}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="reported">Reported</MenuItem>
                        <MenuItem value="not_reported">Not Reported</MenuItem>
                    </Select>
                    <DatePicker
                        label="Month"
                        value={selectedMonth}
                        onAccept={(date: any) => {
                            setSelectedMonth(date);
                        }}
                        views={['month', 'year']}
                    />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Business</TableCell>
                            <TableCell>Business Net Revenue</TableCell>
                            <TableCell>Prorated Ownership</TableCell>
                            <TableCell>Earning Amount</TableCell>
                            <TableCell>Earning Date</TableCell>
                            <TableCell>Period</TableCell>
                            {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {earningsOnDisplay.map(earning => (
                            <InvestorEarningsListMonthDetail key={earning.id} row={earning} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                />
        </div>
    );
};

export default InvestorEarningsMonthList;