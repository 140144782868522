import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import axios from '../../shared/axiosConfig';
import { Revenue } from '../../shared/models';
import { useAuth } from '../../shared/context/AuthContext';
import dayjs, { Dayjs } from 'dayjs';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';


interface MonthlyRevenueFormProps {
    revenue: Revenue | null;
    onClose: () => void;
    isOpen: boolean;
}
type FormValues = {
    start_date: any
    end_date: any
    gross_revenue: number | string
    net_revenue: number | string
    business: number | string | null
}
const MonthlyRevenueForm: React.FC<MonthlyRevenueFormProps> = ({ revenue, onClose, isOpen }) => {
    const { userDetails } = useAuth();
    const { showSnackbar } = useSnackbar();
    const { control, register, handleSubmit, setValue } = useForm<FormValues>({
        defaultValues: {
            start_date: null,
            gross_revenue: '',
            net_revenue: '',
            business: userDetails.business?.id
          }
    });
    

    useEffect(() => {
        if (revenue) {
            setValue('start_date', dayjs(revenue.start_date));
            setValue('end_date', dayjs(revenue.end_date));
            setValue('gross_revenue', revenue.gross_revenue);
            setValue('net_revenue', revenue.net_revenue);
            setValue('business', userDetails.business.id);
        } else {
            resetValues();
        }
    }, [revenue, setValue]);

    const resetValues = () => {
        setValue('start_date', null);
        setValue('end_date', null);
        setValue('gross_revenue', '');
        setValue('net_revenue', '');
        setValue('business', userDetails.business.id);
    }

    const onSubmit = async (data: any) => {
        
        const start_date = data.start_date;
        const new_data = {...data, month: start_date.month()+1, year: start_date.year()}
        delete new_data['start_date']
        console.log("data", new_data)
        
        try{
            if (revenue) {
                await axios.patch(`business-revenue/${revenue.id}/`, new_data);
                showSnackbar("Successfully added monthly revenue", SnackbarSeverity.SUCCESS);
            } else {
                await axios.post('business-revenue/', new_data);
                showSnackbar("Successfully added monthly revenue", SnackbarSeverity.SUCCESS);
            }
            resetValues();
            onClose();
        } catch(error) {
            console.log(error)
            showSnackbar("Failed to add monthly revenue", SnackbarSeverity.ERROR);
        }
        
        
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{revenue ? 'Edit Revenue' : 'Add Revenue'}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}} >
                    
                    <Controller
                        control={control}
                        name="start_date"
                        rules={{ required: true }}
                        render={({ field }) => {
                            return (
                            <DatePicker
                                label="Month"
                                value={field.value}
                                inputRef={field.ref}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                                views={['month', 'year']}
                            />
                            );
                        }}
                    />
                    </div>
                    {/* <TextField label="Date" type="date"  fullWidth margin="normal" InputLabelProps={{ shrink: true }} /> */}
                    <TextField label="Gross Revenue" type="number" {...register("gross_revenue", { required: "Gross Revenue is required" })} fullWidth margin="normal" />
                    <TextField label="Net Revenue" type="number" {...register("net_revenue", { required: "Net Revenue is required" })} fullWidth margin="normal" />
                    {/* <TextField label="Restaurant ID" {...register("restaurant", { required: "Restaurant ID is required" })} fullWidth margin="normal" /> */}
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">{revenue ? 'Update' : 'Create'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default MonthlyRevenueForm;