import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import axios from '../shared/axiosConfig';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { useSnackbar, SnackbarSeverity } from '../shared/services/SnackbarService';

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormHelperText from '@mui/material/FormHelperText';

const logo = require("../assets/images/sliceshares.webp")
// const logo = require("../../assets/images/dinevest.webp")

const schema = Yup.object().shape({
    first_name: Yup.string().label("First Name").trim().required().min(3).max(64),
    last_name: Yup.string().label("Last Name").trim().required().min(3).max(64),
    password: Yup.string().label("Password").trim().required().min(3).max(64),
    confirmPassword: Yup.string().label("Confirm Password").trim().required().oneOf([Yup.ref('password')], 'Passwords must match'),
    email: Yup.string().label("Email").trim().required().email("Please enter a valid email"),
    user_type: Yup.string().label("User Type").required().oneOf(["investor", "restaurant_owner"]),
    business_name: Yup.string().label("Business Name").trim().when("user_type", ([user_type], schema) => {
        if (user_type === "restaurant_owner") {
            return schema.required().min(3).max(64);
        }
        return schema.notRequired();
    }),
    company_name: Yup.string().label("Company Name").trim().when("user_type", ([user_type], schema) => {
        if (user_type === "restaurant_owner") {
            return schema.required().min(3).max(64);
        }
        return schema.notRequired();
    }),
    total_valuation: Yup.number().label("Total Valuation").when("user_type", ([user_type], schema) => {
        if (user_type === "restaurant_owner") {
            return schema.required("Total Valuation is required").moreThan(10000, "Valuation needs to be more than $10000");
        }
        return schema.notRequired();
    }),
    max_ownership_split: Yup.number().label("Max Split").when("user_type", ([user_type], schema) => {
        if (user_type === "restaurant_owner") {
            return schema.required("Max Split is required").min(1, "Max split needs to be more than 1%").max(99, "Max Split needs to be less than 99%");
        }
        return schema.notRequired();
    }),
    minimum_purchase_value: Yup.number().label("Min Purchase Value").when("user_type", ([user_type], schema) => {
        if (user_type === "restaurant_owner") {
            return schema.required("Min Purchase Value is required").min(1, "Min Purchase Value needs to be more than 1%").max(99, "Min Purchase Value needs to be less than 99%");
        }
        return schema.notRequired();
    }),
//   work: Yup.string().label("Work").oneOf(["unemployed", "employed"]),
//   company: Yup.string().when("work", ([work], schema) => {
//     if (work === "employed") {
//       return schema.required().min(3).max(64);
//     }
//     return schema.notRequired();
//   }),
//   role: Yup.string().when("work", ([work], schema) => {
//     if (work === "employed") {
//       return schema.required().min(3).max(64);
//     }
//     return schema.notRequired();
//   }),
});

interface RegisterFormInputs {
    email: string;
    first_name: string;
    last_name: string;
    password: string;
    confirmPassword: string;
    user_type: string;
    business_name?: string;
    company_name?: string;
    total_valuation?: number;
    max_ownership_split?: number;
    minimum_purchase_value?: number;
}

const RegisterForm: React.FC = () => {
    const { register, handleSubmit, watch, formState: { errors }, setError } = useForm<RegisterFormInputs, any, RegisterFormInputs>({
        mode: "onChange",
        resolver: yupResolver(schema),
        defaultValues: {
            business_name: "",
            company_name: "",
            total_valuation: 0,
            max_ownership_split: 0,
            minimum_purchase_value: 0
        }
    });
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    console.log("errors", errors)
    const navigate = useNavigate();
    const password = watch('password'); // Watch the password field for validation
    const { showSnackbar } = useSnackbar();
    const user_type = watch("user_type");

    const onSubmit = async (data: RegisterFormInputs) => {
        try {
            await axios.post('register/', {...data});
            // Handle successful registration (e.g., redirect or show a success message)
            showSnackbar("Registration Successful!", SnackbarSeverity.SUCCESS);
            navigate("/login");
            console.log("User registered successfully", data);
        } catch (error: any) {
            showSnackbar("Registration Failed!", SnackbarSeverity.ERROR)
            console.error("Registration failed", error);
            const errorData = error.response.data;
            if (error.response.status == 400) {
                for(const errorKey in errorData) {
                    if (errorKey == 'email') {
                        setError('email', {type: 'remote', 'message': errorData[errorKey]}, {shouldFocus: true})
                    }
                    else if (errorKey == 'password') {
                        setError('password', {type: 'remote', 'message': errorData[errorKey]})
                    }
                }
            }
            
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center"
        }}>
            <h1>Slice Shares</h1>
            <div style={{
                width: '30%'
            }}>
                <img src={logo} alt="Logo" style={{
                            display: 'block',
                            width: '100%',  
                    }} />
            </div>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <h2 style={{textAlign: 'center'}}>Register</h2>
                <TextField
                    label="Email"
                    variant="outlined"
                    {...register("email", { required: "Email is required" })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    fullWidth
                    margin="normal"
                />
                <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                <TextField
                    label="First Name"
                    variant="outlined"
                    {...register("first_name", { required: "First Name is required" })}
                    error={!!errors.first_name}
                    helperText={errors.first_name?.message}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    label="Last Name"
                    variant="outlined"
                    {...register("last_name", { required: "Last Name is required" })}
                    error={!!errors.last_name}
                    helperText={errors.last_name?.message}
                    fullWidth
                    margin="normal"
                />
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        {...register("password", {
                            required: "Password is required",
                            minLength: { value: 6, message: "Password must be at least 6 characters long" }
                        })}
                        error={!!errors.password}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            // onMouseUp={handleMouseUpPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                    <FormHelperText>{errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}</FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        {...register("confirmPassword", {
                            required: "Please confirm your password",
                            validate: value => value === password || "Passwords do not match"
                        })}
                        error={!!errors.confirmPassword}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            // onMouseDown={handleMouseDownPassword}
                            // onMouseUp={handleMouseUpPassword}
                            edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                    <FormHelperText>{errors.confirmPassword && <span style={{ color: 'red' }}>{errors.confirmPassword.message}</span>}</FormHelperText>
                </FormControl>
                </div>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="user-type-label">User Type</InputLabel>
                    <Select
                        labelId="user-type-label"
                        {...register("user_type", { required: "User Type is required" })}
                        error={!!errors.user_type}
                    >
                        <MenuItem value="investor">Investor</MenuItem>
                        <MenuItem value="restaurant_owner">Restaurant Owner</MenuItem>
                    </Select>
                    <FormHelperText>{errors.user_type && <span style={{ color: 'red' }}>{errors.user_type.message}</span>}</FormHelperText>
                </FormControl>
                {
                    user_type == 'restaurant_owner' && 
                    <>
                    <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                        <TextField
                            label="Business Name"
                            variant="outlined"
                            {...register("business_name")}
                            error={!!errors.business_name}
                            helperText={errors.business_name?.message}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Company Name"
                            variant="outlined"
                            {...register("company_name")}
                            error={!!errors.company_name}
                            helperText={errors.company_name?.message}
                            fullWidth
                            margin="normal"
                        />
                        </div>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="outlined-adornment-amount">Total Valuation</InputLabel>
                            <OutlinedInput
                                id="outlined-total-valuation-amount"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                label="Total Valuation"
                                {...register("total_valuation")}
                            error={!!errors.total_valuation}
                            
                            />
                            <FormHelperText>{errors.total_valuation && <span style={{ color: 'red' }}>{errors.total_valuation.message}</span>}</FormHelperText>
                        </FormControl>
                        <div style={{display: 'flex', justifyContent: 'space-between', gap: 10}}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="outlined-adornment-amount">Max Split</InputLabel>
                            <OutlinedInput
                                id="outlined-max-split-amount"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                label="Max Split"
                                {...register("max_ownership_split")}
                            error={!!errors.max_ownership_split}
                            
                            />
                            <FormHelperText>{errors.max_ownership_split && <span style={{ color: 'red' }}>{errors.max_ownership_split.message}</span>}</FormHelperText>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="outlined-adornment-amount">Min Purchase Value</InputLabel>
                            <OutlinedInput
                                id="outlined-min-purchase-value"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                label="Min Purchase Value"
                                {...register("minimum_purchase_value")}
                            error={!!errors.minimum_purchase_value}
                            
                            />
                            <FormHelperText>{errors.minimum_purchase_value && <span style={{ color: 'red' }}>{errors.minimum_purchase_value.message}</span>}</FormHelperText>
                        </FormControl>
                        </div>
                    </>
                }
                
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} >
                <Button type="submit" variant="contained" color="primary" style={{width: '100%', marginBottom: '1rem'}}>
                    Register
                </Button>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<LoginIcon />}
                    onClick={() => {
                        navigate("/login")
                    }}
                    style={{width: '100%', marginBottom: '1rem'}}
                    >
                    Login

                </Button>
                </div>
            </form>
        </div>
    );
};

export default RegisterForm;