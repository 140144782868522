import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../shared/axiosConfig';
import BusinessForm from './BusinessForm';
import { Business } from '../shared/models';

const BusinessList: React.FC = () => {
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [search, setSearch] = useState<string>('');
    const [editing, setEditing] = useState<Business | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchBusinesses();
    }, []);

    const fetchBusinesses = async () => {
        const response = await axios.get<Business[]>('businesses/');
        setBusinesses(response.data);
    };

    const handleEdit = (business: Business) => {
        setEditing(business);
        setOpen(true);
    };
    const handleAdd = () => {
        setEditing(null);
        setOpen(true);
    };

    const handleDelete = async (id: number) => {
        await axios.delete(`businesses/${id}/`);
        fetchBusinesses();
    };
    const onClose = () => { 
        setEditing(null); 
        setOpen(false);
        fetchBusinesses(); 
    }

    return (
        <div>
            <h2>Businesses</h2>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ marginBottom: 20 }}
                />
                <Button variant="outlined" startIcon={<AddIcon />}
                onClick={() => handleAdd()}
                >
                Add
                </Button>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Valuation</TableCell>
                            <TableCell>Ownership Split</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {businesses.filter(business => business.name.toLowerCase().includes(search.toLowerCase())).map(business => (
                            <TableRow key={business.id}>
                                <TableCell>{business.name}</TableCell>
                                <TableCell>{business.stock_profile.total_valuation}</TableCell>
                                <TableCell>{business.stock_profile.max_ownership_split}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEdit(business)}>Edit</Button>
                                    <Button onClick={() => handleDelete(business.id)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <BusinessForm isOpen={open} business={editing} onClose={onClose} />
        </div>
    );
};

export default BusinessList;