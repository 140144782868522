import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from '../shared/axiosConfig';
import { Business } from '../shared/models';

interface BusinessFormProps {
    business: Business | null;
    onClose: () => void;
    isOpen: boolean;
}

const BusinessForm: React.FC<BusinessFormProps> = ({ business, onClose, isOpen }) => {
    const { register, handleSubmit, setValue } = useForm<Business>();

    useEffect(() => {
        if (business) {
            setValue('name', business.name);
            setValue('company', business.company);
            setValue('is_restaurant', business.is_restaurant);

            setValue('stock_profile.total_valuation', business.stock_profile.total_valuation);
            setValue('stock_profile.max_ownership_split', business.stock_profile.max_ownership_split);
            setValue('stock_profile.minimum_purchase_slab', business.stock_profile.minimum_purchase_slab);
        } else {
            setValue('name', '');
            setValue('company', '');
            setValue('stock_profile.total_valuation', '');
            setValue('stock_profile.max_ownership_split', '');
            setValue('stock_profile.minimum_purchase_slab', '');
        }
    }, [business, setValue]);

    const onSubmit = async (data: Business) => {
        if (business) {
            await axios.put(`businesses/${business.id}/`, data);
        } else {
            await axios.post('businesses/', data);
        }
        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{business ? 'Edit Business' : 'Add Business'}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField label="Name" {...register("name", { required: "Name is required" })} fullWidth margin="normal" />
                    <TextField label="Company" {...register("company", { required: "Company is required" })} fullWidth margin="normal" />
                    <TextField label="Total Valuation" type="number" {...register("stock_profile.total_valuation", { required: "Total Valuation is required" })} fullWidth margin="normal" />
                    <TextField label="Ownership Split" type="number" {...register("stock_profile.max_ownership_split", { required: "Ownership Split is required" })} fullWidth margin="normal" />
                    <TextField label="Minimum Percentage Slab" type="number" {...register("stock_profile.minimum_purchase_slab")} fullWidth margin="normal" />
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">{business ? 'Update' : 'Create'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default BusinessForm;