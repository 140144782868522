import React, { useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Pagination, IconButton, Collapse, Box, Typography } from '@mui/material';
import axios from '../../shared/axiosConfig';
import InvestorEarningsForm from './InvestorEarningsForm';
import { InvestorEarnings, InvestorProratedEarnings, PaginatedResponse } from '../../shared/models';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import DateTimeValue from '../../shared/components/DateTimeValue';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PercentageValue from '../../shared/components/PercentageValue';
import InvestorEarningsListDetail from './InvestorEarningsListDetail';

const InvestorEarningsList: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const [earnings, setEarnings] = useState<InvestorEarnings[]>([]);
    
    const [totalEarnings, setTotalEarnings] = useState<any>();
    const [search, setSearch] = useState<string>('');
    const [editing, setEditing] = useState<InvestorEarnings | null>(null);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {
        fetchEarnings();
        fetchTotalEarnings();
    }, []);

    const fetchEarnings = async () => {
        try {
            let url = 'investor-earnings/';
            setLoading(true);
            const response = await axios.get<PaginatedResponse<InvestorEarnings>>(url, {
                params: {
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setEarnings(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch restaurants", error);
            showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
    };
    
    const fetchTotalEarnings = async () => {
        try {
            let url = 'investor-earnings/total_earnings';
            const response = await axios.get(url);
            setTotalEarnings(response.data.total_earnings);
            
        } catch (error) {
            console.error("Failed to fetch restaurants", error);
            showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
    };

    const handleEdit = (earning: InvestorEarnings) => {
        setEditing(earning);
    };

    const handleDelete = async (id: number) => {
        await axios.delete(`investor-earnings/${id}/`);
        fetchEarnings();
    };

    const Row = (props: { row: any }) => {
        const { row } = props;
        const [open, setOpen] = React.useState(false);
        const [earningDetails, setEarningDetails] = useState<InvestorProratedEarnings[]>();

        useEffect(() => {
            if (open) {
                if (!earningDetails) {
                    fetchEarningDetails()
                }
            }
        }, [open])

        const fetchEarningDetails = async () => {
            try {
                let url = `investor-earnings/${row.id}/earning_details/`;
                setLoading(true);
                const response = await axios.get<InvestorProratedEarnings[]>(url, {
                    params: {
                        page: page,
                        page_size: rowsPerPage
                    }
                });
                setEarningDetails(response.data);
            } catch (error) {
                console.error("Failed to fetch restaurants", error);
                showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
            } finally {
                setLoading(false);
            }
            
            
        };
      
        return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell>{row.business.name}</TableCell>
            
            <TableCell><CurrencyAmount amount={row.business_revenue.net_revenue} /></TableCell>
            <TableCell><PercentageValue value={row.avg_ownership} /></TableCell>
            <TableCell><CurrencyAmount amount={row.earning_amount}/> </TableCell>
            <TableCell><DateTimeValue value={row.earning_date} includeTime={true} /></TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Earnings</TableCell>
                                <TableCell>Prorated Ownership</TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>End</TableCell>
                                <TableCell>Period</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {earningDetails && earningDetails.map((earningDetail, iindex) => (
                                <TableRow>
                                    <TableCell>{iindex + 1}</TableCell>
                                    <TableCell><CurrencyAmount amount={earningDetail.prorated_earnings} /></TableCell>
                                    <TableCell><PercentageValue value={earningDetail.prorated_ownership} /></TableCell>
                                    <TableCell><DateTimeValue value={earningDetail.start_date} /></TableCell>
                                    <TableCell><DateTimeValue value={earningDetail.end_date} /></TableCell>
                                    <TableCell>{earningDetail.period_days} days</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );
    }
    

    return (
        <div>
            <h2>My Earnings</h2>
            {/* <TextField
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 20 }}
            /> */}
            <div>
                <h4>Total Earnings - <CurrencyAmount amount={totalEarnings} /></h4>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Business</TableCell>
                            <TableCell>Business Net Revenue</TableCell>
                            <TableCell>Prorated Ownership</TableCell>
                            <TableCell>Earning Amount</TableCell>
                            <TableCell>Earning Date</TableCell>
                            <TableCell>Period</TableCell>
                            {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {earnings.map(earning => (
                            <InvestorEarningsListDetail key={earning.id} row={earning} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                />
        </div>
    );
};

export default InvestorEarningsList;