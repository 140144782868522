import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const axiosInstance = axios.create({
    baseURL: `${BASE_URL}/api/v1/core/`, // Update with your backend URL
});

// Add a request interceptor to include the JWT token in the headers
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access'); // Get the token from local storage
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;