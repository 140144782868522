import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';
import PermanentDrawer from './shared/components/Drawer';
import BusinessList from './components/BusinessList';
import InvestorList from './components/owner/InvestorList';
import MonthlyRevenueList from './components/owner/MonthlyRevenueList';
import InvestorEarningsList from './components/investor/InvestorEarningsList';
import LoginForm from './components/LoginForm';
import { AuthProvider, useAuth } from './shared/context/AuthContext';
import RegisterForm from './components/RegisterForm';
import { SnackbarProvider } from './shared/services/SnackbarService';
import { UserType } from './shared/models';
import RestaurantList from './components/investor/RestaurantList';
import InvestmentList from './components/investor/InvestmentList';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import MenuAppBar from './shared/components/MenuAppBar';
import OwnerPayoutsList from './components/owner/OwnerPayoutsList';
import InvestmentHistory from './components/investor/InvestmentHistory';
import InvestorPayoutsList from './components/investor/InvestorPayoutsList';
import OwnerPendingPayoutsList from './components/owner/OwnerPendingPayoutsList';
import InvestorEarningsMonthList from './components/investor/InvestorEarningsMonthList';
import InvestorAwaitedPayoutsList from './components/investor/InvestorAwaitedPayoutsList';

const App: React.FC = () => {
    return (
        <SnackbarProvider>
            <AuthProvider>
                <AppComponent />
            </AuthProvider>
        </SnackbarProvider>
    )
}
const AppComponent: React.FC = () => {
    const { isAuthenticated, userDetails } = useAuth();
    // if (!isAuthenticated) {
    //     window.location.href = '/login'
    // }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
            {
                isAuthenticated &&
                    <>
                        <MenuAppBar />
                        <PermanentDrawer />
                        {/* <Container> */}
                            <main style={{ marginLeft: 240, padding: '20px' }}>
                                
                                {
                                    userDetails.user_type == UserType.INVESTOR &&
                                    <Routes>
                                        <Route path="/businesses/invest" element={<ProtectedRoute component={RestaurantList} />} />
                                        <Route path="/investments" element={<ProtectedRoute component={InvestmentList} />} />
                                        <Route path="/investment-history" element={<ProtectedRoute component={InvestmentHistory} />} />
                                        <Route path="/earnings-monthly" element={<ProtectedRoute component={InvestorEarningsMonthList} />} />
                                        <Route path="/earnings" element={<ProtectedRoute component={InvestorEarningsList} />} />
                                        <Route path="/awaited-investor-payouts" element={<ProtectedRoute component={InvestorAwaitedPayoutsList} />} />
                                        <Route path="/investor-payouts" element={<ProtectedRoute component={InvestorPayoutsList} />} />
                                    </Routes>
                                }
                                {
                                    userDetails.user_type == UserType.RESTAURANT_OWNER &&
                                    <Routes>
                                        <Route path="/businesses/invest" element={<ProtectedRoute component={BusinessList} />} />
                                        <Route path="/businesses" element={<ProtectedRoute component={BusinessList} />} />
                                        <Route path="/investors" element={<ProtectedRoute component={InvestorList} />} />
                                        <Route path="/revenues" element={<ProtectedRoute component={MonthlyRevenueList} />} />
                                        <Route path="/payouts" element={<ProtectedRoute component={OwnerPayoutsList} />} />
                                        <Route path="/pending-payouts" element={<ProtectedRoute component={OwnerPendingPayoutsList} />} />
                                    </Routes>
                                }
                                {
                                    userDetails.is_superuser &&
                                    <Routes>
                                        <Route path="/businesses/invest" element={<ProtectedRoute component={BusinessList} />} />
                                        <Route path="/businesses" element={<ProtectedRoute component={BusinessList} />} />
                                        <Route path="/investors" element={<ProtectedRoute component={InvestorList} />} />
                                        <Route path="/revenues" element={<ProtectedRoute component={MonthlyRevenueList} />} />
                                        
                                    </Routes>
                                }
                                
                            </main>
                        {/* </Container> */}
                    </>
            }
            {
                !isAuthenticated &&
                <Container>
                
                <main style={{ padding: '20px' }}>
                {/* <h1>Restaurant Shares Split App</h1> */}
                    <Routes>
                        <Route path="/login" element={<LoginForm />} />
                        <Route path="/register" element={<RegisterForm />} />
                    </Routes>
                </main>
            </Container>
            }
            
        </Router>
        </LocalizationProvider>
    );
};

const ProtectedRoute = ({ component: Component }: { component: React.FC }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? <Component /> : <LoginForm />;

};

export default App;