import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, MenuItem, Select, Autocomplete } from '@mui/material';
import axios from '../../shared/axiosConfig';
import { Investor, InvestorPayouts, PaginatedResponse } from '../../shared/models';
import { Controller, useForm } from 'react-hook-form';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import { useAuth } from '../../shared/context/AuthContext';

interface InvestorPayoutsFormProps {
    payout?: InvestorPayouts | null;
    onClose: () => void;
    open: boolean
    investorId?: number | string;
    initAmount?: number | string;
}

type FormValues = {
    payout_amount: string
    investor: {id: number|string, label?: string}
}

const InvestorPayoutsForm: React.FC<InvestorPayoutsFormProps> = ({ payout, onClose, open, initAmount, investorId }) => {
    const { control, register, handleSubmit, watch, formState: { errors }, setError, setValue } = useForm<FormValues>();
    const [investors, setInvestors] = useState<any[]>([]);

    const { userDetails } = useAuth();
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        fetchInvestors();
    }, []);

    const fetchInvestors = async () => {
        try {
            let url = 'investors/for_dropdown/';
            const response = await axios.get<Investor[]>(url, {
                params: {
                    // business_id: userDetails.business?.id,
                    investor_businesses__business: userDetails.business?.id,
                }
            });
            const investorOptions = response.data.map(inv => {
                const option = {label: `${inv.first_name} ${inv.last_name}`, id: inv.id}
                return option
            })
            
            setInvestors(investorOptions);
            if (investorId) {
                const selectedOption = investorOptions.find((io) => io.id == investorId)
                if (selectedOption) {
                    setValue("investor", selectedOption)
                }
            }
        } catch (error) {
            console.error("Failed to fetch investors", error);
            showSnackbar("Failed to fetch investors. Please try again.", SnackbarSeverity.ERROR);
        } finally {
        }
    };

    useEffect(() => {
        if (payout) {
            setValue("payout_amount", payout.payout_amount.toString());
            // setValue("investor", payout.investor.toString());
        } else {
            resetValues();
        }
        
    }, [payout]);

    useEffect(() => {
        if (investorId) {
            if (investors) {
                const selectedOption = investors.find((io) => io.id == investorId)
                if (selectedOption) {
                    setValue("investor", selectedOption)
                }
            }
        }
        if (initAmount) {
            setValue("payout_amount", initAmount.toString());
        }
    }, [investorId, initAmount]);

    const resetValues = () => {
        setValue("payout_amount", '');
        setValue("payout_amount", '');
    }

    const onSubmit = async (data: any) => {
        try {
            const payloaddata = { payout_amount: data.payout_amount, investor: data.investor.id };

            if (payout) {
                await axios.put(`business-payouts/${payout.id}/`, payloaddata);
            } else {
                await axios.post('business-payouts/', payloaddata);
            }
            showSnackbar("Successfully added a payout", SnackbarSeverity.SUCCESS);
            resetValues();
            onClose();
        }
        catch(error) {
            console.log(error)
            showSnackbar("Failed to add a payout", SnackbarSeverity.ERROR);
        }
        
    };

    return (
        
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{payout ? 'Edit Payout' : 'Add Payout'}</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField label="Payout Amount" 
                        fullWidth margin="normal"
                        {...register("payout_amount", { required: "Amount is required" })} 
                        error={!!errors.payout_amount}
                        helperText={errors.payout_amount ? errors.payout_amount.message : ''}
                    />
                    <FormControl fullWidth margin="normal">
                        {/* <InputLabel id="user-type-label">Investor</InputLabel> */}
                        <Controller
                            control={control}
                            name="investor"
                            rules={{ required: true }}
                            render={({ field }) => {
                                return (
                                    <Autocomplete
                                        disablePortal
                                        value={field.value}
                                        options={investors}
                                        renderInput={(params) => <TextField {...params} label="Investor" />}
                                        multiple={false}
                                        onChange={(e, val) => {
                                            field.onChange(val);
                                        }}
                                    />
                                )
                            }}
                        />
                        {/* <Select
                            labelId="user-type-label"
                            {...register("investor", { required: "Investor is required" })}
                            error={!!errors.investor}
                        >
                            {
                                investors && investors.length > 0 && investors.map(inv => {
                                    return (
                                        <MenuItem value={inv.id}>{inv.first_name} {inv.last_name}</MenuItem>
                                    )
                                })
                            }
                        </Select> */}
                        {errors.investor && <span style={{ color: 'red' }}>{errors.investor?.message ? errors.investor?.message : ''}</span>}
                    </FormControl>
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">{payout ? 'Update' : 'Create'}</Button>
                    </DialogActions>
                </form>
            </DialogContent>
            
        </Dialog>
        
    );
};

export default InvestorPayoutsForm;