import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Pagination } from '@mui/material';
import axios from '../../shared/axiosConfig';
import AddIcon from '@mui/icons-material/Add';
import { Investor, InvestorPayouts, PaginatedResponse } from '../../shared/models';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import { useAuth } from '../../shared/context/AuthContext';
import DateTimeValue from '../../shared/components/DateTimeValue';
import InvestorAwaitedPayoutsListDetail from './InvestorAwaitedPayoutsListDetail';

const InvestorAwaitedPayoutsList: React.FC = () => {
    const { userDetails } = useAuth();
    const [payouts, setPayouts] = useState<InvestorPayouts[]>([]);
    
    const [open, setOpen] = useState<boolean>(false);
    const [payoutsPendingAmount, setPayoutsPendingAmount] = useState<any>();
    const [search, setSearch] = useState<string>('');
    const [amount, setAmount] = useState<number|string>('');
    const [investorId, setInvestorId] = useState<number|string>('');

    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {
        fetchPayouts();
        fetchPayoutsPendingAmount();
    }, []);

    

    const fetchPayouts = async () => {
        try {
            let url = 'investor-payouts/pending_payouts/';
            setLoading(true);
            const response = await axios.get<PaginatedResponse<InvestorPayouts>>(url, {
                params: {
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setPayouts(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch Payouts", error);
            showSnackbar("Failed to fetch Payouts. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
    };
    const fetchPayoutsPendingAmount = async () => {
        const response = await axios.get<any>('investor-payouts/pending_amount/');
        setPayoutsPendingAmount(response.data);
    };

    return (
        <div>
            <h2>Investor Pending Payouts</h2>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    <h4>Total Pending - <CurrencyAmount amount={payoutsPendingAmount} /></h4>
                </div>
            </div>
                
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Business</TableCell>
                            <TableCell>Pending Amount</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payouts.map((payout, index) => (
                            <InvestorAwaitedPayoutsListDetail row={payout} index={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                />
        </div>
    );
};

export default InvestorAwaitedPayoutsList;