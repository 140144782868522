import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../shared/context/AuthContext';
import { TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useSnackbar } from '../shared/services/SnackbarService';

const logo = require("../assets/images/sliceshares.webp")
// const logo = require("../../assets/images/dinevest.webp")

interface LoginFormInputs {
    username: string;
    password: string;
}

const LoginForm: React.FC = () => {
    const { login } = useAuth();
    const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();
    const navigate = useNavigate();
    const { showSnackbar } = useSnackbar();

    const onSubmit = async (data: LoginFormInputs) => {
        await login(data.username, data.password);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: "center"
        }}>
            <h1>Slice Shares</h1>
            <div style={{
                width: '30%'
            }}>
                <img src={logo} alt="Logo" style={{
                            display: 'block',
                            width: '100%',  
                    }} />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h2 style={{textAlign: 'center'}}>Login</h2>
                <TextField
                    label="Username"
                    variant="outlined"
                    {...register("username", { required: "Username is required" })}
                    error={!!errors.username}
                    helperText={errors.username?.message}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Password"
                    type="password"
                    variant="outlined"
                    {...register("password", { required: "Password is required" })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    fullWidth
                    margin="normal"
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} >
                <Button type="submit" variant="contained" color="primary" style={{width: '100%', marginBottom: '1rem'}}>
                    Login
                </Button>
                <Button
                    style={{width: '100%', marginBottom: '1rem'}}
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<AppRegistrationIcon />}
                    onClick={() => {
                        navigate("/register")
                    }}
                    >
                    Register

                </Button>
                </div>
            </form>
        </div>
    );
};

export default LoginForm;