import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';
import axios from '../../shared/axiosConfig';
import InvestorForm from '../InvestorForm';
import { Investor, PaginatedResponse } from '../../shared/models';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import { useAuth } from '../../shared/context/AuthContext';
import PercentageValue from '../../shared/components/PercentageValue';

const InvestorList: React.FC = () => {
    const [investors, setInvestors] = useState<Investor[]>([]);
    const [search, setSearch] = useState<string>('');
    const [editing, setEditing] = useState<Investor | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const { userDetails } = useAuth();
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();


    useEffect(() => {
        fetchInvestors();
    }, []);

    const fetchInvestors = async () => {
        try {
            let url = 'investors/';
            setLoading(true);
            const response = await axios.get<PaginatedResponse<Investor>>(url, {
                params: {
                    // business_id: userDetails.business?.id,
                    investor_businesses__business: userDetails.business?.id,
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setInvestors(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch restaurants", error);
            showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
    };

    const handleEdit = (investor: Investor) => {
        setEditing(investor);
    };

    const handleDelete = async (id: number) => {
        await axios.delete(`investors/${id}/`);
        fetchInvestors();
    };

    return (
        <div>
            <h2>Investors</h2>
            <TextField
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ marginBottom: 20 }}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Ownership</TableCell>
                            <TableCell>Profile</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {investors.filter(investor => investor.personal_profile.toLowerCase().includes(search.toLowerCase())).map(investor => (
                            <TableRow key={investor.id}>
                                <TableCell>{investor.first_name} {investor.last_name}</TableCell>
                                <TableCell><PercentageValue value={investor.investor_business?.current_ownership_percentage} /></TableCell>
                                <TableCell>{investor.personal_profile}</TableCell>
                                
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <InvestorForm investor={editing} onClose={() => { setEditing(null); fetchInvestors(); }} />
        </div>
    );
};

export default InvestorList;