import React, { useEffect } from 'react';
import { Collapse, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { UserType } from '../models';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleIcon from '@mui/icons-material/People';

const logo = require("../../assets/images/sliceshares.webp")
// const logo = require("../../assets/images/dinevest.webp")


enum COLLAPSE_KEYS {
    INV_PAYOUTS='investor_payouts',
    REST_PAYOUTS='restaurant_payouts',
    INV_EARNINGS='investor_earnings'
}


const PermanentDrawer: React.FC = () => {
    const { logout, userDetails } = useAuth();
    const [open, setOpen] = React.useState<any>({});
    useEffect(() => {
        open[COLLAPSE_KEYS.INV_PAYOUTS] = false;
        open[COLLAPSE_KEYS.REST_PAYOUTS] = false;
        open[COLLAPSE_KEYS.INV_EARNINGS] = false;
    }, [])

    const setCollapseOpen = (key: string) => {
        open[key] = !open[key]
        setOpen({...open})
    }

    return (
        <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: 240,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 240,
                    boxSizing: 'border-box',
                },
            }}
        >
        <Toolbar style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: ".5em"
        }}>
            <IconButton edge="start" color="inherit" aria-label="logo">
                <img src={logo} alt="Logo" style={{
                        height: '40px',  // Adjust size as needed
                        marginRight: 2,
                }} />
            </IconButton>
            <Typography style={{
                fontSize: "1.25em",
                fontWeight: 600,
                userSelect: "none"
            }}>Slice Shares</Typography>
        </Toolbar>
            {
                userDetails.user_type == UserType.INVESTOR &&
                <List>
                    <ListItemButton component={Link} to="/businesses/invest">
                        <ListItemIcon>
                            <StorefrontIcon />
                        </ListItemIcon>
                        <ListItemText primary="Businesses" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/investments">
                        <ListItemIcon>
                            <BusinessCenterIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Investments" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/investment-history">
                        <ListItemIcon>
                            <CurrencyExchangeIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Transactions" />
                    </ListItemButton>
                    <ListItemButton onClick={() => {
                        setCollapseOpen(COLLAPSE_KEYS.INV_EARNINGS);
                    }}>
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Earnings" />
                        {open[COLLAPSE_KEYS.INV_EARNINGS] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open[COLLAPSE_KEYS.INV_EARNINGS]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/earnings-monthly">
                                <ListItemIcon>
                                    <AttachMoneyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Monthly Earnings" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/earnings">
                                <ListItemIcon>
                                    <AttachMoneyIcon />
                                </ListItemIcon>
                                <ListItemText primary="Earnings History" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                    <ListItemButton onClick={() => {
                        setCollapseOpen(COLLAPSE_KEYS.INV_PAYOUTS);
                    }}>
                        <ListItemIcon>
                            <PaidIcon />
                        </ListItemIcon>
                        <ListItemText primary="My Payouts" />
                        {open[COLLAPSE_KEYS.INV_PAYOUTS] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open[COLLAPSE_KEYS.INV_PAYOUTS]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/awaited-investor-payouts">
                                <ListItemIcon>
                                    <PaidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Awaited Payouts" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/investor-payouts">
                                <ListItemIcon>
                                    <PaidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Paid Payouts" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
            }
            {
                userDetails.user_type == UserType.RESTAURANT_OWNER &&
                <List>
                    <ListItemButton component={Link} to="/investors">
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Investors" />
                    </ListItemButton>
                    <ListItemButton component={Link} to="/revenues">
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary="Revenues" />
                    </ListItemButton>
                    <ListItemButton onClick={() => {
                        setCollapseOpen(COLLAPSE_KEYS.REST_PAYOUTS);
                    }}>
                        <ListItemIcon>
                            <PaidIcon />
                        </ListItemIcon>
                        <ListItemText primary="Payouts" />
                        {open[COLLAPSE_KEYS.REST_PAYOUTS] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open[COLLAPSE_KEYS.REST_PAYOUTS]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/pending-payouts">
                                <ListItemIcon>
                                    <PaidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Pending Payouts" />
                            </ListItemButton>
                            <ListItemButton sx={{ pl: 4 }} component={Link} to="/payouts">
                                <ListItemIcon>
                                    <PaidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Paid Payouts" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
            }
            {
                userDetails.is_superuser &&
                <List>
                    <ListItem button component={Link} to="/businesses">
                        <ListItemText primary="Restaurants" />
                    </ListItem>
                    <ListItem button component={Link} to="/investors">
                        <ListItemText primary="Investors" />
                    </ListItem>
                    <ListItem button component={Link} to="/revenues">
                        <ListItemText primary="Revenues" />
                    </ListItem>
                    <ListItem button component={Link} to="/earnings">
                        <ListItemText primary="Investor Earnings" />
                    </ListItem>
                    <ListItem button component={Link} to="/payouts">
                        <ListItemText primary="Investor Payouts" />
                    </ListItem>
                </List>
            }
            
        </Drawer>
    );
};

export default PermanentDrawer;