import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, TextField, Button, CircularProgress, 
    Dialog, DialogTitle, DialogContent, DialogActions, Pagination, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from '../../shared/axiosConfig';
// import BusinessForm from './BusinessForm';
import { Business, PaginatedResponse, PurchaseType } from '../../shared/models';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import PercentageValue from '../../shared/components/PercentageValue';
import InvestmentForm from './InvestmentForm';

const InvestmentList: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    const [investments, setInvestments] = useState<any[]>([]);
    const [purchaseType, setPurchaseType] = useState<PurchaseType>(PurchaseType.BUY);
    const [search, setSearch] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState<Business | null>(null);
    const [currentOwnershipPercentage, setCurrentOwnershipPercentage] = useState<number | string>('');
    const [purchaseAmount, setPurchaseAmount] = useState<number | string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {
        fetchMyInvestments();
    }, [searchTerm, page]);

    const fetchMyInvestments = async () => {
        try {
            let url = 'investor-businesses/';
            setLoading(true);
            const response = await axios.get<PaginatedResponse<any>>(url, {
                params: {
                    search: searchTerm,
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setInvestments(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch restaurants", error);
            showSnackbar("Failed to fetch restaurants. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
        setLoading(false)
    };

    const handleSell = (investment: any) => {
        setSelectedRestaurant(investment.business);
        setPurchaseType(PurchaseType.SELL)
        setOpen(true);
    };
    const handleBuy = (investment: any) => {
        setSelectedRestaurant(investment.business);
        setPurchaseType(PurchaseType.BUY)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentOwnershipPercentage('');
        setSelectedRestaurant(null);
        fetchMyInvestments();
    };
    const handlePercentageChange = (e: any) => {
        const currentPercentage = +e.target.value;
        const  totalValuation = selectedRestaurant?.stock_profile.total_valuation ? +selectedRestaurant?.stock_profile.total_valuation : 0
        const pamount =  totalValuation * (currentPercentage/100)
        setCurrentOwnershipPercentage(currentPercentage);
        setPurchaseAmount(pamount)
    }
    const handleSubmit = async () => {
        // Logic for handling investment submission
        if (selectedRestaurant) {
            try {
                let url = 'investor-investments/';
                setLoading(true);
                const response = await axios.post(url, {
                    business_id: selectedRestaurant.id,
                    ownership_percentage: currentOwnershipPercentage,
                    purchase_amount: purchaseAmount
                });
                showSnackbar(`Investment of ${currentOwnershipPercentage}% initiated for ${selectedRestaurant.name}!`, SnackbarSeverity.SUCCESS); 
            } catch (error) {
                console.error("Failed to save investment", error);
                showSnackbar("Failed to save investment. Please try again.", SnackbarSeverity.ERROR);
            } finally {
                setLoading(false);
            }
            
            
            handleClose();
        }
    };
    // const handleSubmit = async () => {
    //     // Logic for handling investment submission
    //     if (selectedRestaurant) {
    //         try {
    //             let url = 'investor-businesses/';
    //             setLoading(true);
    //             const response = await axios.post(url, {
    //                 business_id: selectedRestaurant.id,
    //                 current_ownership_percentage: currentOwnershipPercentage
    //             });
    //             showSnackbar(`Investment of ${currentOwnershipPercentage}% initiated for ${selectedRestaurant.name}!`, SnackbarSeverity.SUCCESS); 
    //         } catch (error) {
    //             console.error("Failed to save investment", error);
    //             showSnackbar("Failed to save investment. Please try again.", SnackbarSeverity.ERROR);
    //         } finally {
    //             setLoading(false);
    //         }
            
            
    //         handleClose();
    //     }
    // };

    return (
        <div>
            <h2>My Investments</h2>
            {loading ? (
                <CircularProgress />
            ) : (<>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{ marginBottom: 20 }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Business Name</TableCell>
                            <TableCell>Business Valuation</TableCell>
                            <TableCell>My Ownership</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {investments.map((investment, index) => (
                            <TableRow key={investment.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{investment.business.name}</TableCell>
                                <TableCell>{<CurrencyAmount amount={investment.business.stock_profile.total_valuation} />}</TableCell>
                                <TableCell>{<PercentageValue value={investment.current_ownership_percentage} />}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleBuy(investment)}>Buy</Button>
                                    <Button onClick={() => handleSell(investment)}>Sell</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    variant="outlined"
                    shape="rounded"
                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                />
            <InvestmentForm isOpen={open} selectedRestaurant={selectedRestaurant} onClose={handleClose} purchaseType={purchaseType} />
            </>
            )}
        </div>
        
    );
};

export default InvestmentList;