import React, { memo, useEffect, useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Pagination, IconButton, Collapse, Box, Typography } from '@mui/material';
import axios from '../../shared/axiosConfig';
import InvestorEarningsForm from './InvestorEarningsForm';
import { InvestorEarnings, InvestorProratedEarnings, PaginatedResponse } from '../../shared/models';
import { SnackbarSeverity, useSnackbar } from '../../shared/services/SnackbarService';
import CurrencyAmount from '../../shared/components/CurrencyAmount';
import DateTimeValue from '../../shared/components/DateTimeValue';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PercentageValue from '../../shared/components/PercentageValue';

interface RowProps  {
    row: any,
    index: number
}

const InvestorAwaitedPayoutsListDetail = (props: RowProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const { showSnackbar } = useSnackbar();
    
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(9); // Number of restaurants per page
    const [totalPages, setTotalPages] = useState<number>();

    const { row, index } = props;
    const [open, setOpen] = React.useState(false);
    const [earningDetails, setEarningDetails] = useState<InvestorEarnings[]>();

    useEffect(() => {
        if (open) {
            if (!earningDetails) {
                fetchEarningDetails()
            }
        }
    }, [open])

    const fetchEarningDetails = async () => {
        try {
            let url = `investor-payouts/pending_payouts_details/`;
            setLoading(true);
            const response = await axios.get<PaginatedResponse<InvestorEarnings>>(url, {
                params: {
                    business_id: row.business.id,
                    page: page,
                    page_size: rowsPerPage
                }
            });
            setEarningDetails(response.data.results);
            setTotalPages(Math.ceil(response.data.count/rowsPerPage));
        } catch (error) {
            console.error("Failed to fetch payout details", error);
            showSnackbar("Failed to fetch payout details. Please try again.", SnackbarSeverity.ERROR);
        } finally {
            setLoading(false);
        }
        
        
    };
      
    return (
          <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>{index + 1}</TableCell>
              <TableCell>{row.business.name}</TableCell>
              <TableCell><CurrencyAmount amount={row.pending_amount ? row.pending_amount :'0'} /></TableCell>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            
            </TableRow>
            <TableRow>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                
                                
                                <TableCell>Revenue</TableCell>
                                <TableCell>Prorated Ownership</TableCell>
                                <TableCell>Earnings</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Period</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {earningDetails && earningDetails.map((earningDetail, iindex) => {
                                return (
                                    <TableRow>
                                        <TableCell>{iindex + 1}</TableCell>
                                        
                                        
                                        <TableCell><CurrencyAmount amount={earningDetail.business_revenue.net_revenue} /></TableCell>
                                        <TableCell><PercentageValue value={earningDetail.avg_ownership} /></TableCell>
                                        <TableCell><CurrencyAmount amount={earningDetail.earning_amount}/> </TableCell>
                                        <TableCell><DateTimeValue value={earningDetail.earning_date} /></TableCell>
                                        <TableCell><DateTimeValue value={earningDetail.business_revenue.start_date} /> - <DateTimeValue value={earningDetail.business_revenue.end_date} /></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        variant="outlined"
                        shape="rounded"
                        style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}

                    />
                    </div>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </React.Fragment>
        );

};

export default memo(InvestorAwaitedPayoutsListDetail);