// src/services/SnackbarService.tsx
import React, { createContext, useContext, useState } from 'react';
import { Snackbar, SnackbarContent } from '@mui/material';

interface SnackbarContextType {
    showSnackbar: (message: string, severity: SnackbarSeverity) => void;
}

export enum SnackbarSeverity {
    SUCCESS='success',ERROR='error',INFO='info',WARNING='warning'
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
};

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState<SnackbarSeverity>(SnackbarSeverity.SUCCESS);

    const showSnackbar = (message: string, severity: SnackbarSeverity) => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <SnackbarContent
                    message={message}
                    style={{
                        backgroundColor: severity === 'success' ? '#4caf50' :
                                        severity === 'error' ? '#f44336' :
                                        severity === 'warning' ? '#ff9800' :
                                        '#2196f3',
                    }}
                />
            </Snackbar>
        </SnackbarContext.Provider>
    );
};